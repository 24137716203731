
    /* Cookie banner */
    .CookieConsent {
      display: flex !important;
      flex-wrap: wrap;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
    }

    .CookieConsent > div{
      flex: 1 !important;
    }

    .CookieConsent button{
      border-radius: 5px !important;
      padding: 10px !important;
      background-color: rgb(38, 82, 124, 0.9) !important;
      color: rgb(255, 255, 255) !important;
      font-weight: 700 !important;
    }

    .CookieConsent button:hover {
      background-color: rgb(38, 82, 124, 0.3) !important;
      color: rgba(255, 255, 255, 0.471) !important;
    }

    /* Datepicker  */
    .custom-datepicker {
      width: 10em;
      padding: 0;
      background: transparent;
      border:none;
      /* cursor: ; */
      /* color: #7fb5d542 */
      
    }
    
    .custom-calendar {
      position: fixed !important;
      right:-100px;
    }
    
    /* App.css tai oma CSS-tiedosto */
.custom-time-picker .react-datepicker__time-container {
  width: auto;
  min-width: 200px;
}

.custom-time-picker .react-datepicker__time {
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  position: relative;
}

.custom-time-picker .react-datepicker__time-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-time-picker .react-datepicker__time-list-item {
  background-color: #007bff;
  color: white;
  margin: 5px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.custom-time-picker .react-datepicker__time-list-item:hover {
  background-color: #0056b3;
}

.custom-time-picker .react-datepicker__time-list-item--selected {
  background-color: #28a745;
}


    /*Authentication screen*/
    .authContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      max-height: 80vh;
      width: 90vw;
      margin: 50px auto;
    }


    .authContainer > .authForm{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .authContainer > .authForm > p{
      font-size: 15px;
      font-weight: 400;
    }

    .authForm > header{
      font-size: 20px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.195)
    }

    /* .password-container > input {
      margin-bottom: 10px;
      height: 2.5em;
      width: 100%;
      border-radius: 5px;
    } */

    .password-container{
      position: relative;
    }

    .show-password{
      position: absolute;
      right: 5px; /* Säädä tarvittaessa oikealle */
      top: 44%;
      transform: translateY(-50%);
      cursor: pointer;
      /* width: 24px; Säädä kuvakkeen koko */
      /* height: 24px; */
      /* background-image: url('show-password-icon.png'); Korvaa kuvan polku omalla ikonillasi */
      background-size: cover;
    }

    #password {
      padding-right: 35px; /* Jätä tilaa kuvakkeelle */
    }

    .darkModeTextColor, p, header{
      color:white;
    }

    /* Error  */

    .authContainer > .errorContainer {
      max-height: 6em;
      margin: 5px auto 20px auto;
      padding: 0 10px;
      max-width: 15em;
      overflow-y: auto;
    }

    .errorContainer > div {
      height: 90%;
    }

    .errorContainer > div > p {
      margin:0;
      padding: 5px;
    }

    /* nav */

    .nav-container{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      position: fixed; 
      bottom: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
      padding: 10px 0;
    }

    .nav-container > .nav-link {
      text-decoration: none;
      color: white;
    }

    .disabled-link {
      pointer-events: none;
      cursor: default;
      color: rgba(0, 0, 0, 0.356); /* Haluamasi väri estetyille linkeille */
    }
    

    /* Workout content */

    .workout-container {
      width: 100vw;
      height: 4rem;
      display: flex; 
      flex-direction:row; 
      justify-content:space-between; 
      border-bottom: 1px solid rgb(212, 212, 212);
      margin-bottom: 10px;
    }

    .workout-content {
      display: flex; 
      flex-direction:column;
      justify-content:flex-end
    }


    /* loader */
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    /* Notification */

    .notification-icon {
      display: inline-block;
      cursor: pointer;
    }
    
    .shake {
      animation: shake 0.6s ease-in-out 2;
    }
    
    @keyframes shake {
      0% { transform: rotate(0deg); }
      25% { transform: rotate(-10deg); }
      50% { transform: rotate(10deg); }
      75% { transform: rotate(-10deg); }
      100% { transform: rotate(0deg); }
    }