.connection-indicator-wrapper {
    position: relative;
  }
  
  .blur {
    filter: blur(5px); /* Blurrataan sisältö */
    pointer-events: none; /* Estetään taustan elementtien käyttö */
  }
  
  .overlay {
    position: fixed;
    top: 50;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Tumma läpinäkyvä kerros */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999; /* Näkyy muiden elementtien päällä */
  }
  
  .loader {
    color: white;
    font-size: 24px;
    font-weight: bold;
  }

  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* ajatuskupla */
  
  .thought-bubble {
    position: relative;
    display: inline-block;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    animation: pop 0.5s ease-in-out;
  }
  
  .thought-bubble::after,
  .thought-bubble::before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .thought-bubble::before {
    width: 20px;
    height: 20px;
    bottom: -10px;
    left: 75%;
  }
  
  .thought-bubble::after {
    width: 10px;
    height: 10px;
    bottom: -20px;
    left: 84%;
  }
  
  .letter {
    font-size: 20px;
    display: inline-block;
    animation: bounce 8s infinite ease-in-out;
  }
  
  .letter:nth-child(1) {
    animation-delay: 0s;
  }
  .letter:nth-child(2) {
    animation-delay: 0.3s;
  }
  .letter:nth-child(3) {
    animation-delay: 0.6s;
  }
  .letter:nth-child(4) {
    animation-delay: 0.9s;
  }
  .letter:nth-child(5) {
    animation-delay: 1.2s;
  }
  .letter:nth-child(6) {
    animation-delay: 1.5s;
  }
  .letter:nth-child(7) {
    animation-delay: 1.8s;
  }
  .letter:nth-child(8) {
    animation-delay: 2.1s;
  }
  .letter:nth-child(9) {
    animation-delay: 2.4s;
  }
  .letter:nth-child(10) {
    animation-delay: 2.7s;
  }
  .letter:nth-child(11) {
    animation-delay: 3s;
  }
  .letter:nth-child(12) {
    animation-delay: 3.3s;
  }
  .letter:nth-child(13) {
    animation-delay: 3.6s;
  }
  .letter:nth-child(14) {
    animation-delay: 3.9s;
  }
  .letter:nth-child(15) {
    animation-delay: 4.2s;
  }
  .letter:nth-child(16) {
    animation-delay: 4.5s;
  }
  .letter:nth-child(17) {
    animation-delay: 4.8s;
  }
  .letter:nth-child(18) {
    animation-delay: 5.1s;
  }
  .letter:nth-child(19) {
    animation-delay: 5.4s;
  }
  .letter:nth-child(20) {
    animation-delay: 5.7s;
  }
  .letter:nth-child(21) {
    animation-delay: 6s;
  }
  .letter:nth-child(22) {
    animation-delay: 6.3s;
  }
  .letter:nth-child(23) {
    animation-delay: 6.6s;
  }

  
  @keyframes bounce {
    0%, 100% {
      /* transform: scale(1.3); */
      opacity: 1;
    }
    50% {
      /* transform: scale(1); */
      opacity: 0;
    }
  }
  

/* write text */


.scanner span {
  color: transparent;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
}

.scanner span::before {
  content: "Connecting to the server...";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 4px solid #17FEFF;
  overflow: hidden;
  color: #17FEFF;
  animation: load91371 3s linear infinite;

}

@keyframes load91371 {
  0%, 10% {
    width: 0;
  }

  10%,20%,30%,40%,50%,60%,70%,80%,90%,100% {
    border-right-color: transparent;
  }

  11%,21%,31%,41%,51%,61%,71%,81%,91% {
    border-right-color: #17FEFF;
  }

  60%, 80%, 100% {
    width: 290px;
  }
}
  