
.rdt input {
  background-color: transparent;
  width: auto; /* Aseta leveys automaattiseksi */
  min-width: 10ch; /* Aseta vähimmäisleveys */
  max-width: 16ch; /* Aseta enimmäisleveys */
  box-sizing: content-box; /* Sisällön mukaan */
  border: none;
  font-size: 16px;
  margin: auto 3px;
}