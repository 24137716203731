.muscle {
    fill: #ccc;
    cursor: pointer;
    transition: fill 0.3s ease;
  }
  .muscle:hover {
    fill: #f7a8a8;
  }
  .active {
    fill: #ff4d4d;
  }